<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        newPath="/convocacao-exames/parametros"
        editPath="/convocacao-exames/"
        :expander="false"
        subtitle="Convocação de exames"
        tooltip="Convocação de exames periódicos"
        :showActionItems="false"
        nomeTelaDoManual="convocacao-exames-list"
    >
        <template #columns>
            <Column field="id" header="Cod." :sortable="true" sortField="id"></Column>
            <Column field="nomeSolicitante" header="Solicitante" :sortable="true" sortField="nomeSolicitante"></Column>
            <Column field="pendentes" header="Pendentes" :sortable="true" sortField="pendentes"></Column>
            <Column field="concluidos" header="Concluídos" :sortable="true" sortField="concluidos"></Column>
            <Column field="percentual" header="Percentual" :sortable="true" sortField="percentual"></Column>
            <Column field="status" header="Status" :sortable="true" sortField="status"></Column>
            <Column field="etapa" header="Etapa" :sortable="true" sortField="etapa"></Column>
            <Column field="msgErro" header="Erro" :sortable="true" sortField="msgErro"></Column>
            <Column field="createdAt" header="Criado" :sortable="true" sortField="created_at">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                </template>
            </Column>
            <Column field="updatedAt" header="Alterado" :sortable="true" sortField="updated_at">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.updatedAt) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import BaseService from '../../services/BaseService';
import { getCurrentCompany, getCurrentCustomers } from '@/services/store';

export default {
    data() {
        return {
            tenantCustomers: [],
            service: null
        };
    },
    mounted() {
        this.tenantCustomers = getCurrentCustomers();
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    watch: {
        tenant() {
            this.load();
        },
        currentCustomer() {
            this.tenantCustomers = getCurrentCustomers();
            this.load();
        }
    },
    created() {
        this.service = new BaseService('/convocacao');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        }
    }
};
</script>
